import React, { useState, useEffect } from 'react';
import { checkSize } from '../Responsive/context';

import './column.css';

export default checkSize(function Col(props) {
  const {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    w,
    windowSM,
    windowMD,
    windowLG,
    windowXL,
    windowXXL,
    windowW,
    min,
    children,
    style,
    className,
  } = props;

  const [width, setWidth] = useState(false);
  const [order, setOrder] = useState(false);

  useEffect(() => {
    getWidth();
    getOrder();
  }, [windowSM, windowMD, windowLG, windowXL, windowXXL, windowW]);

  const getWidth = () => {
    let colWidth = `100%`;
    if (xs?.width) {
      colWidth = xs.width;
    }
    if (windowSM && sm?.width) {
      colWidth = sm.width;
    }
    if (windowMD && md?.width) {
      colWidth = md.width;
    }
    if (windowLG && lg?.width) {
      colWidth = lg.width;
    }
    if (windowXL && xl?.width) {
      colWidth = xl.width;
    }
    if (windowXXL && xxl?.width) {
      colWidth = xxl.width;
    }
    if (windowW && w?.width) {
      colWidth = w.width;
    }
    setWidth(colWidth);
  };

  const getOrder = () => {
    let colOrder = false;
    if (xs?.order) {
      colOrder = xs.order;
    }
    if (windowSM && sm?.order) {
      colOrder = sm.order;
    }
    if (windowMD && md?.order) {
      colOrder = md.order;
    }
    if (windowLG && lg?.order) {
      colOrder = lg.order;
    }
    if (windowXL && xl?.order) {
      colOrder = xl.order;
    }
    if (windowXXL && xxl?.order) {
      colOrder = xxl.order;
    }
    if (windowW && w && w.order) {
      colOrder = w.order;
    }
    setOrder(colOrder);
  };

  return (
    <div
      className={`column${className !== undefined ? ` ${className}` : ``}`}
      style={{
        ...style,
        flex: `0 0 ${width || min}`,
        maxWidth: width || min,
        order: order || false,
      }}
    >
      {children}
    </div>
  );
});
