import React from 'react';

const ResponsiveContext = React.createContext(null);

export const checkSize = Component => props =>
  (
    <ResponsiveContext.Consumer>
      {responsive => <Component {...props} {...responsive} />}
    </ResponsiveContext.Consumer>
  );

export default ResponsiveContext;
