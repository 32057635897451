import React from 'react';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';

import {
  AccountPlaceholder,
  CartPlaceholder,
  LogoPlaceholder,
  MenuPlaceholder,
  MessagePlaceholder,
  MobileMenuPlaceholder,
} from './placeholders';

import { TitleColour } from '../../utils/variables';
import './style.css';

import loadComponents from '../Loadable';

import { CtaButtonPlaceholder } from '../Cta/placeholders';
import { AddressPlaceholder } from '../Address/placeholder';

const Logo = loadComponents('logo');
const Menu = loadComponents('menu');
const MobileMenu = loadComponents('menu-mobile');
const Cart = loadComponents('cart');
const Address = loadComponents('address');
const Message = loadComponents('header-message');
const Account = loadComponents('header-account');
const CtaButton = loadComponents('cta-button');

export default class Header extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.updateOffset.bind(this), {
      passive: true,
    });
    this.updateOffset();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateOffset.bind(this), {
      passive: true,
    });
  }

  updateOffset() {
    if (this._isMounted) {
      this.setState({
        offset: this.header && this.header.firstElementChild.offsetLeft,
      });
    }
  }

  render() {
    const { cart } = this.props;
    const { offset } = this.state;

    return (
      <header className="siteHeader">
        <div className="top">
          <Container>
            <Row align="center">
              <Col xs={{ width: 'auto' }}>
                <Logo fallback={<LogoPlaceholder />} />
              </Col>
              <Col className="headerContact" xs={{ width: `auto` }}>
                <div
                  style={{
                    paddingLeft: `30px`,
                    borderLeft: `1px solid rgba(0,0,0,0.1)`,
                  }}
                >
                  <Address header fallback={<AddressPlaceholder header />} />
                </div>
              </Col>
              <Col
                className="headerMessage"
                xs={{ width: `420px` }}
                style={{ marginLeft: `auto` }}
              >
                <Message fallback={<MessagePlaceholder />} />
              </Col>
              <Col className="headerButton" xs={{ width: `auto` }}>
                <CtaButton fallback={<CtaButtonPlaceholder />} />
              </Col>
              <Col
                className="headerMobileMenu"
                xs={{ width: `auto` }}
                style={{ marginLeft: `auto` }}
              >
                <MobileMenu fallback={<MobileMenuPlaceholder />} />
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="bottom"
          style={bottomStyle}
          ref={node => {
            this.header = node;
          }}
        >
          <Container>
            <Row align="center">
              <Col
                className="desktopMenu"
                xs={{ width: 'auto' }}
                style={{
                  textAlign: 'right',
                  position: 'relative',
                }}
              >
                <Menu fallback={<MenuPlaceholder />} />
              </Col>
              <Col className="headerCart" xs={{ width: 'auto' }}>
                {cart && (
                  <Cart position={offset} fallback={<CartPlaceholder />} />
                )}
              </Col>
              <Col className="headerAccount" xs={{ width: `auto` }}>
                <Account fallback={<AccountPlaceholder />} />
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    );
  }
}

const bottomStyle = {
  display: `flex`,
  alignItems: `center`,
  backgroundColor: TitleColour,
};
