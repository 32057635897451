/* eslint-disable import/prefer-default-export */
import React from 'react';
import { MainColour } from '../../utils/variables';

import Placeholder from '../Placeholder';
import Loader from '../Placeholder/loader';

import { iconSize, outerStyle } from './style';
import './style.css';

const textHeight = `22px`;

export const AddressPlaceholder = ({ header }) => (
  <div className="address" style={outerStyle}>
    <ul>
      <li className="location">
        <Placeholder
          height={iconSize}
          width={iconSize}
          colour={MainColour}
          style={{ borderRadius: iconSize, marginTop: `-30px` }}
        />
        <span>
          <Loader
            style={{ ...textStyle, minHeight: textHeight, maxWidth: `80%` }}
          />
          <Loader
            style={{ ...textStyle, minHeight: textHeight, maxWidth: `70%` }}
          />
        </span>
      </li>
      <li className="phone">
        <Placeholder
          height={iconSize}
          width={iconSize}
          colour={MainColour}
          style={{ borderRadius: iconSize }}
        />
        <span>
          <Loader
            style={{ ...textStyle, minHeight: textHeight, maxWidth: `40%` }}
          />
        </span>
      </li>
      {!header && (
        <li className="email">
          <Placeholder
            height={iconSize}
            width={iconSize}
            colour={MainColour}
            style={{ borderRadius: iconSize }}
          />
          <span>
            <Loader
              style={{ ...textStyle, minHeight: textHeight, maxWidth: `100%` }}
            />
          </span>
        </li>
      )}
    </ul>
  </div>
);

const textStyle = {
  margin: `8px 0`,
};
