const STORAGE_KEY = '__cart';

let saveListener = null;
const listen = cb => {
  saveListener = cb;
};

const list = key =>
  JSON.parse(
    typeof window !== 'undefined' && localStorage.getItem(key || STORAGE_KEY),
  ) || [];

const save = (data, key) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key || STORAGE_KEY, JSON.stringify(data));
  }
  if (saveListener) saveListener(list(key || STORAGE_KEY));
};

const clear = key => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key || STORAGE_KEY);
  }
  if (saveListener) saveListener(list(key || STORAGE_KEY));
};

const get = id => list().find(product => product.id === id);

const exists = id => !!get(id);

const add = product => {
  if (isValid(product)) {
    if (exists(product.id)) {
      update(product.id, 'quantity', parseFloat(get(product.id).quantity) + 1);
    } else {
      save(list().concat({ ...product, quantity: 1 }));
    }
  }
};

const remove = id => save(list().filter(product => product.id !== id));

const quantity = (id, diff) =>
  exists(id) && get(id).quantity + diff > 0
    ? update(id, 'quantity', get(id).quantity + diff)
    : remove(id);

const update = (id, field, value) =>
  save(
    list().map(product =>
      product.id === id ? { ...product, [field]: value } : product,
    ),
  );

const total = cb =>
  list().reduce((sum, product) => totalVal(cb, sum, product), 0);

const totalVal = (cb, sum, product) => {
  const val = isCallback(cb) ? cb(sum, product) : (sum += subtotal(product));
  return val;
};

const destroy = () => clear();

const onChange = cb => (isCallback(cb) ? listen(cb) : console.log(typeof cb));

const isValid = product => product.id && product.price;

const subtotal = product =>
  isCalcable(product)
    ? (product.special_price || product.price) * product.quantity
    : 0;

const isCalcable = product => product && product.price && product.quantity;

const isCallback = cb => cb && typeof cb === 'function';

export {
  list,
  get,
  add,
  remove,
  update,
  quantity,
  total,
  destroy,
  exists,
  subtotal,
  onChange,
};
