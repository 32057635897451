import React from 'react';

import './placeholder.css';

export default function Placeholder(props) {
  const { className, height, width, colour, style } = props;
  return (
    <div
      className={`placeholder ${className || ``}`}
      style={{
        height,
        width,
        backgroundColor: colour,
        ...style,
      }}
    />
  );
}
