import loadable from '@loadable/component';

const appLibraries = {
  'gatsby-link': loadable(() => import('gatsby'), {
    resolveComponent: components => components.Link,
  }),
  'gatsby-image': loadable(() => import('gatsby-plugin-image'), {
    resolveComponent: components => components.GatsbyImage,
  }),
  'react-helmet': loadable(() => import('react-helmet')),
  'react-switch': loadable(() => import('react-switch')),
};

function loadLibrary(libraryId) {
  const Library = appLibraries[libraryId];
  if (!Library) {
    return null;
  }
  return Library;
}

export default loadLibrary;
