import React from 'react';

const CartContext = React.createContext(null);

export const withCart = Component => props =>
  (
    <CartContext.Consumer>
      {data => <Component {...props} {...data} />}
    </CartContext.Consumer>
  );

export default CartContext;
