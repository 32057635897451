import React, { useState, useEffect } from 'react';

import { list, total } from './storage';

import CartContext from './context';

const CartProvider = props => {
  const { children } = props;
  const [items, setItems] = useState(null);
  const [cartTotal, setTotal] = useState(null);
  const [count, setCount] = useState(null);

  useEffect(() => {
    function initTotal() {
      updateTotal();
    }
    initTotal();
  }, []);

  const updateTotal = () => {
    setItems(list());
    setTotal(total());
    setCount(list().length);
  };

  const cartData = {
    items,
    total: cartTotal,
    count,
    updateTotal,
  };

  return (
    <CartContext.Provider value={cartData}>{children}</CartContext.Provider>
  );
};

export default CartProvider;
