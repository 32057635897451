import React from 'react';

import './loader.css';

export default function Loader(props) {
  const { className, style } = props;
  return (
    <div
      className={`loader ${className || ``}`}
      style={{
        ...style,
        position: `relative`,
        width: `100%`,
        backgroundColor: `#f0f0f0`,
      }}
    />
  );
}
