/* eslint-disable import/prefer-default-export */
import React from 'react';

import Placeholder from '../Placeholder';
import Loader from '../Placeholder/loader';

export const LogoPlaceholder = () => (
  <Placeholder
    className="logo"
    /* width="250px" */
    // height="126px"
    colour="#222"
  />
);

export const MenuPlaceholder = () => (
  <div
    style={{
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
      width: `290px`,
    }}
  >
    {Array(3)
      .fill(null)
      .map((_, i) => {
        const key = `menuPlaceholder${i}`;
        return (
          <Placeholder
            key={key}
            width="70px"
            height="30px"
            colour="#fff"
            style={{ margin: `1rem 0`, borderRadius: `30px` }}
          />
        );
      })}
  </div>
);

export const CartPlaceholder = ({ size = `24px` }) => (
  <Placeholder
    width={size}
    height={size}
    colour="#fff"
    style={{
      borderRadius: size,
    }}
  />
);

export const MessagePlaceholder = () => (
  <Loader style={{ minHeight: `42px`, maxWidth: `378px` }} />
);

export const AccountPlaceholder = ({ size = `24px` }) => (
  <div style={{ display: `flex`, alignItems: `center` }}>
    <Placeholder
      width={size}
      height={size}
      colour="#fff"
      style={{
        borderRadius: size,
      }}
    />
    <Placeholder
      className="accountLinkText"
      width="172px"
      height="22px"
      colour="#fff"
      style={{ marginLeft: `15px` }}
    />
  </div>
);

export const MobileMenuPlaceholder = ({ size = `36px` }) => (
  <Placeholder
    width={size}
    height={size}
    colour="#000"
    style={{
      borderRadius: `4px`,
    }}
  />
);
