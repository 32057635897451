import React, { useEffect, useState } from 'react';

import getFirebase, { FirebaseContext } from '../components/Firebase';
import withAuthentication from '../components/Session/withAuthentication';
import CartProvider from '../components/Cart/data';
import ResponsiveProvider from '../components/Responsive';

import Header from '../components/Header';

import './style.css';

import loadComponents from '../components/Loadable';

const Seo = loadComponents('seo');
const Footer = loadComponents('footer');

export default function Layout(props) {
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    function initFirebase() {
      const firebase = getFirebase();
      setFirebase(firebase);
    }
    initFirebase();
  }, []);

  return (
    <FirebaseContext.Provider value={firebase}>
      <AppWithAuthentication props={props} loaded={firebase !== null} />
    </FirebaseContext.Provider>
  );
}

const AppWithAuthentication = withAuthentication(({ props, loaded }) => (
  <ResponsiveProvider>
    <CartProvider>
      <Seo title={props.title} seo={props.seo} location={props.location} />
      <Header cart={!props.cart} />
      <div id="cta" />
      <main style={{ padding: props.padding /*  || `0 0 5.625rem` */ }}>
        {props.children}
      </main>
      {loaded && !props.cart && <Footer quote={props.quote} />}
    </CartProvider>
  </ResponsiveProvider>
));
