import React from 'react';

import './row.css';

export default function Row(props) {
  const { children, className, align, justify, style, ...other } = props;
  return (
    <div
      className={`row ${className || ``}`}
      style={{
        alignItems: align,
        justifyContent: justify,
        ...style,
      }}
      {...other}
    >
      {children}
    </div>
  );
}
