import React, { useState, useEffect } from 'react';

import ResponsiveContext from './context';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } =
    typeof window !== 'undefined' && window;

  return {
    width,
    height,
    windowW: width >= 1600,
    windowXXL: width >= 1400,
    windowXL: width >= 1200,
    windowLG: width >= 992,
    windowMD: width >= 768,
    windowSM: width >= 576,
  };
}

export default function ResponsiveProvider({ children }) {
  const [windowSize, setWindow] = useState(getWindowDimensions());
  useEffect(() => {
    function updateDimensions() {
      setWindow(getWindowDimensions());
    }
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={{ ...windowSize }}>
      {children}
    </ResponsiveContext.Provider>
  );
}
