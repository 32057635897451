import { initializeApp } from 'firebase/app';

import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getRedirectResult,
  signOut,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  sendEmailVerification,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  deleteUser,
  signInWithCredential,
  fetchSignInMethodsForEmail,
  linkWithPopup,
  unlink,
} from 'firebase/auth';

import {
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  get,
} from 'firebase/database';

const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_APP_ID,
};

class Firebase {
  constructor(/* app */) {
    const app = initializeApp(config);

    /* Helper */
    this.emailAuthProvider = EmailAuthProvider;
    this.facebookAuthProvider = FacebookAuthProvider;
    this.googleAuthProvider = GoogleAuthProvider;

    /* Firebase APIs */
    this.auth = getAuth(app);
    this.db = getDatabase(app);

    /* Social Sign In Method Provider */
    this.googleProvider = new GoogleAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
  }

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doCredentialSignIn = (email, password) =>
    signInWithCredential(this.auth, email, password);

  doSignInWithGoogle = () => signInWithPopup(this.auth, this.googleProvider);

  doSignInWithFacebook = () =>
    signInWithPopup(this.auth, this.facebookProvider);

  mobileSignInWithGoogle = () =>
    signInWithRedirect(this.auth, this.googleProvider);

  mobileSignInWithFacebook = () =>
    signInWithRedirect(this.auth, this.facebookProvider);

  getResult = () => getRedirectResult(this.auth);

  getUserAdditional = credential => getAdditionalUserInfo(credential);

  doSignOut = () => signOut(this.auth);

  doPasswordReset = email => sendPasswordResetEmail(this.auth, email);

  verifyPasswordReset = code => verifyPasswordResetCode(this.auth, code);

  confirmPassword = (code, password) =>
    confirmPasswordReset(this.auth, code, password);

  doSendEmailVerification = user => sendEmailVerification(user);

  verifyEmail = code => applyActionCode(this.auth, code);

  reAuthenticateUser = (user, credential) =>
    reauthenticateWithCredential(user, credential);

  doPasswordUpdate = (user, password) => updatePassword(user, password);

  deleteAccount = user => deleteUser(user);

  fetchEmailMethods = email => fetchSignInMethodsForEmail(this.auth, email);

  popupLink = (user, provider) => linkWithPopup(user, provider);

  unlinkSocial = (user, provider) => unlink(user, provider);

  // Merge Auth and DB User API
  onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(this.auth, authUser => {
      if (authUser) {
        return onValue(
          this.user(authUser.uid),
          snapshot => {
            const dbUser = snapshot.val();
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            next(authUser);
          },
          { onlyOnce: true },
        );
      }
      return fallback();
    });

  // User API
  user = uid => ref(this.db, `users/${uid}`);

  users = () => ref(this.db, 'users');

  // Wishlist API
  wishlist = uid => ref(this.db, `users/${uid}/wishlist`);

  wishlistItems = (uid, item) => ref(this.db, `users/${uid}/wishlist/${item}`);

  // Address Book API
  address = uid => ref(this.db, `users/${uid}/address`);

  // Orders API
  orders = uid => ref(this.db, `users/${uid}/orders`);

  purchases = uid => ref(this.db, `users/${uid}/purchases`);

  // Preferences API
  preferences = uid => ref(this.db, `users/${uid}/preferences`);

  // Preferences API
  saved = (uid, key) => ref(this.db, `users/${uid}/saved/${key}`);

  /* Read/Write databases */
  getDatabase = ref => get(ref);

  setDatabase = (ref, data) => set(ref, data);

  pushDatabase = (ref, data) => push(ref, data);

  readDatabase = (ref, callback) => onValue(ref, callback);

  deleteDatabase = ref => remove(ref);
}

let firebase;

function getFirebase(/* app, auth, database */) {
  if (!firebase) {
    firebase = new Firebase(/* app, auth, database */);
  }

  return firebase;
}

export default getFirebase;
