/* eslint-disable import/prefer-default-export */
import React from 'react';

import Placeholder from '../Placeholder';

import { MainColour } from '../../utils/variables';

export const CtaButtonPlaceholder = ({ size }) => (
  <Placeholder
    width={size || '310px'}
    height="67px"
    colour={MainColour}
    style={{ borderRadius: `100px` }}
  />
);

export const CtaTitlePlaceholder = ({ className }) => (
  <Placeholder
    className={className}
    width="500px"
    height="38px"
    colour="#fff"
    style={{ maxWidth: `100%` }}
  />
);
