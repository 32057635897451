import loadable from '@loadable/component';

const appComponents = {
  link: loadable(() => import(`../Links`)),
  logo: loadable(() => import(`../Header/logo`)),
  seo: loadable(() => import(`../SEO`)),
  menu: loadable(() => import(`../Menu`)),
  address: loadable(() => import(`../Address`)),
  'header-message': loadable(() => import(`../Header/message`)),
  'header-account': loadable(() => import(`../Header/account`)),
  footer: loadable(() => import(`../Footer`)),
  'footer-copyright': loadable(() => import(`../Footer/copyright`)),
  'footer-contact': loadable(() => import(`../Footer/contact`)),
  'footer-opening-hours': loadable(() => import(`../Footer/opening-hours`)),
  'footer-account': loadable(() => import(`../Footer/account`)),
  'footer-cta': loadable(() => import(`../Footer/cta`)),
  cookies: loadable(() => import(`../Cookies`)),
  'menu-items': loadable(() => import(`../Menu/items`)),
  'menu-mobile': loadable(() => import(`../Menu/mobile`)),
  'menu-hamburger': loadable(() => import(`../Menu/hamburger`)),
  portal: loadable(() => import(`../Portal`)),
  'lightbox-gallery': loadable(() => import(`../Lightbox/gallery`)),
  'lightbox-image': loadable(() => import(`../Lightbox/image`)),
  'lightbox-backdrop': loadable(() => import(`../Lightbox/backdrop`)),
  'lightbox-button': loadable(() => import(`../Lightbox/button`)),
  'lightbox-inner': loadable(() => import(`../Lightbox/inner`)),
  'lightbox-wrapper': loadable(() => import(`../Lightbox/wrapper`)),
  slider: loadable(() => import(`../Slider`)),
  'slider-overlay': loadable(() => import(`../Slider/overlay`)),
  'slider-arrow': loadable(() => import(`../Slider/arrow`)),
  'slider-inner': loadable(() => import(`../Slider/inner`)),
  'slider-dots': loadable(() => import(`../Slider/dots`)),
  // carousel: loadable(() => import(`../Slider/carousel`)),
  homepage: loadable(() => import(`../Homepage`)),
  'homepage-logos': loadable(() => import(`../Homepage/logos`)),
  'homepage-columns': loadable(() => import(`../Homepage/columns`)),
  button: loadable(() => import(`../Button`)),
  'button-input': loadable(() => import(`../Button/input`)),
  'button-link': loadable(() => import(`../Button/link`)),
  'button-submit': loadable(() => import(`../Button/submit`)),
  'button-hidden': loadable(() => import(`../Button/hidden`)),
  h1: loadable(() => import(`../Title`)),
  h2: loadable(() => import(`../Title/h2`)),
  h3: loadable(() => import(`../Title/h3`)),
  h4: loadable(() => import(`../Title/h4`)),
  h5: loadable(() => import(`../Title/h5`)),
  h6: loadable(() => import(`../Title/h6`)),
  container: loadable(() => import(`../Container`)),
  row: loadable(() => import(`../Container/row`)),
  column: loadable(() => import(`../Container/column`)),
  cta: loadable(() => import(`../Cta`)),
  'cta-banner': loadable(() => import(`../Cta/banner`)),
  'cta-modal': loadable(() => import(`../Cta/modal`)),
  'cta-button': loadable(() => import(`../Cta/button`)),
  form: loadable(() => import(`../Form`)),
  'form-alerts': loadable(() => import(`../Form/alerts`)),
  'form-layout': loadable(() => import(`../Form/form`)),
  'form-fields': loadable(() => import(`../Form/fields`)),
  'form-label': loadable(() => import(`../Form/label`)),
  'form-checkbox': loadable(() => import(`../Form/fields/checkbox`)),
  'form-radio': loadable(() => import(`../Form/fields/radio`)),
  'form-input': loadable(() => import(`../Form/fields/input`)),
  'form-textarea': loadable(() => import(`../Form/fields/textarea`)),
  'form-select': loadable(() => import(`../Form/fields/select`)),
  'form-message': loadable(() => import(`../Form/fields/message`)),
  'form-country': loadable(() => import(`../Form/fields/country`)),
  'form-submit': loadable(() => import(`../Form/fields/submit`)),
  subpage: loadable(() => import(`../Subpage`)),
  'subpage-content': loadable(() => import(`../Subpage/content`)),
  'subpage-additional': loadable(() => import(`../Subpage/additional`)),
  'subpage-contact': loadable(() => import(`../Subpage/contact`)),
  'subpage-image': loadable(() => import(`../Subpage/image`)),
  'subpage-image-text': loadable(() => import(`../Subpage/image-text`)),
  team: loadable(() => import(`../Team`)),
  faq: loadable(() => import(`../Faq`)),
  testimonials: loadable(() => import(`../Testimonials`)),
  share: loadable(() => import(`../Share`)),
  cart: loadable(() => import(`../Cart`)),
  'cart-button': loadable(() => import(`../Cart/button`)),
  'cart-items': loadable(() => import(`../Cart/items`)),
  alert: loadable(() => import(`../Alert`)),
  basket: loadable(() => import(`../Basket`)),
  'basket-summary': loadable(() => import(`../Basket/summary`)),
  'basket-items': loadable(() => import(`../Basket/items`)),
  'basket-header': loadable(() => import(`../Basket/header`)),
  checkout: loadable(() => import(`../Checkout`)),
  'checkout-layout': loadable(() => import(`../Checkout/layout`)),
  'checkout-summary': loadable(() => import(`../Checkout/summary`)),
  'checkout-discount': loadable(() => import(`../Checkout/discount`)),
  'checkout-shipping': loadable(() => import(`../Checkout/shipping`)),
  'checkout-payments': loadable(() => import(`../Checkout/payments`)),
  'checkout-totals': loadable(() => import(`../Checkout/totals`)),
  'checkout-form': loadable(() => import(`../Checkout/form`)),
  'account-register': loadable(() => import(`../Register`)),
  'register-form': loadable(() => import(`../Register/form`)),
  'account-login': loadable(() => import(`../Login`)),
  'login-form': loadable(() => import(`../Login/form`)),
  'login-modal': loadable(() => import(`../Login/modal`)),
  'forgotten-password': loadable(() => import(`../ForgottenPassword`)),
  'forgotten-password-form': loadable(() =>
    import(`../ForgottenPassword/form`),
  ),
  'password-change': loadable(() => import(`../PasswordChange`)),
  account: loadable(() => import(`../Account`)),
  'account-sidebar': loadable(() => import(`../Account/sidebar`)),
  'account-dashboard': loadable(() => import(`../Account/dashboard`)),
  'account-contact': loadable(() => import(`../Account/contact`)),
  'account-delete': loadable(() => import(`../Account/delete`)),
  'account-auth': loadable(() => import(`../Account/oob`)),
  'account-orders': loadable(() => import(`../Account/orders`)),
};

function loadComponent(componentId) {
  const Component = appComponents[componentId];
  if (!Component) {
    return null;
  }
  return Component;
}

export default loadComponent;
