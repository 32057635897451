import React from 'react';

import './container.css';

export default function Container(props) {
  const { children, className, ...other } = props;
  return (
    <div className={`container${className ? ` ${className}` : ``}`} {...other}>
      {children}
    </div>
  );
}
