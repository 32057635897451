export const TextColour = '#7A7A7A';
export const TitleColour = '#373938';

export const MainColour = '#D11315';
export const MainDark = '#AA0404';
export const AltColour = '#D16913';
export const AltDark = '#AA4F04';

export const DarkGrey = '#030303';

export const h1Size = '2.25rem';
export const h2Size = '2rem';
export const h3Size = '1.75rem';
export const h4Size = '1.5rem';
export const h5Size = '1.375rem';
export const h6Size = '1.25rem';

export const TextSize = '1.125rem';
export const LineHeight = '1.8';

export const ParagraphSpacing = '1.875rem';
export const TitleSpacing = '2.8125rem';

export const Light = '300';
export const Regular = '400';
export const Medium = '500';
export const Bold = '700';

export const BorderRadius = '30px';

export const Twitter = '#00aced';
export const Facebook = '#3b5998';
export const GooglePlus = '#dd4b39';
export const Pinterest = '#cb2027';
export const LinkedIn = '#007bb6';
export const YouTube = '#bb0000';
export const Tumblr = '#32506d';
export const Instagram = '#bc2a8d';
export const Soundcloud = '#ff3a00';
